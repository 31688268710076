@font-face {
  font-family: MyriadPro_Light;
  src: url("./assets/fonts/MyriadPro-Light.otf");
}

@font-face {
  font-family: MyriadPro_Bold;
  src: url("./assets/fonts/MyriadPro-Bold.OTF");
}

@font-face {
  font-family: MyriadPro_Medium;
  src: url("./assets/fonts/MyriadPro-Medium.OTF");
}

@font-face {
  font-family: MyriadPro_Regular;
  src: url("./assets/fonts/MyriadPro-Regular.OTF");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, MyriadPro_Light,
    MyriadPro_Bold, MyriadPro_Medium, MyriadPro_Regular, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Style the entire scrollbar */
::-webkit-scrollbar {
  width: 12px; /* Set the width of the scrollbar */
  background: #2f2f2f; /* Background color of the scrollbar track */
}

/* Style the draggable part of the scrollbar */
::-webkit-scrollbar-thumb {
  background: #4a4a4a; /* Color of the draggable part */
  border-radius: 10px; /* Rounded corners for the draggable part */
}

/* Style the scrollbar on hover */
::-webkit-scrollbar-thumb:hover {
  background: #787878; /* Change color when hovered */
}

/* Style the corner of the scrollbar (where two scrollbars meet) */
::-webkit-scrollbar-corner {
  background: #2f2f2f; /* Color of the corner */
}

/* Style the track (part of the scrollbar not covered by the draggable part) */
::-webkit-scrollbar-track {
  background: #2f2f2f; /* Background color of the track */
}

/* Style the track on hover */
::-webkit-scrollbar-track:hover {
  background: #333333; /* Change color when hovered */
}


.gm-style .gm-style-iw {
  font-weight: 600;
  font-size: 20px;
  overflow: hidden;
}

.answer {
  color: #ececec;
}